import { FormAddress, BillingAddress } from "./address";

/**
 * Metadata from the issuer that contains display information for card linking.
 */
export type BankCardInformation = {
  /** URL of the external card art (may be proxied on backend) */
  card_art: string;
  /** Card network of the card retrieved from the issuer (eg. VISA) */
  network: string;
  /** TODO: find out what this is */
  usage_type: string;
  /** DBA name of the issuer */
  issuerDBA: string;
  /** Country code where the card can be used */
  country: string;
  /** Rewards points on the card-- will need conversion rates */
  existing_rewards_points?: number;
  /**Todo: determine what's a the actual property name */
  /** Not implemented */
  // rewards_name?: string;
  /** Not implemented */
  // existing_rewards_conversion_rate?: number;
};

export type NewCard = {
  /** The create and update api accepts either full address or just an address uid **/
  billing_address_id?: string;
  billing_address?: FormAddress;
  /** Expiration date for the card */
  expiry: string;
  /** Account number of the card */
  number: string;
  /** Name of the card owner/authorized user */
  owner: string;

  /**Custom Name for the card **/
  card_nickname?: string;
  cvv?: string;
};

export enum BillingAddressModeOptions {
  SHIPPING = "SHIPPING",
  SAVED = "SAVED",
  NEW = "NEW",
}

/**
 * A card that is being added via manual form entry.
 */
export interface FormCard extends NewCard {
  billing_address_same_as_shipping?: boolean;
  billing_address_mode?: BillingAddressModeOptions;
  billing_address_edited?: boolean;
  is_default_address?: boolean;
  is_default?: boolean;
}

export type InlineFormCard = FormCard & BillingAddress;

export type CardDuplicates = {
  /** The card ID of the card that we WANT to display for duplicates. Ex: the CL card version */
  card_id: string;
  /** The duplicates of the cards we cant to display. Ex: the manual card version(s) */
  duplicates: ManualCard[];
};

// Statuses returned by the BE that we handle specifically
export enum CardStatus {
  deleted = "DELETED",
  allDeleted = "ALL_DELETED",
  locked = "LOCKED",
  notMerchantAccepted = "NOT_MERCHANT_ACCEPTED",
  temporaryFailure = "TEMPORARY_FAILURE",
  pendingIssuerVerification = "PENDING_ISSUER_VERIFICATION",
  sessionLimit = "CANT_BE_LINKED_THIS_SESSION",
}

export enum CardProductType {
  CREDIT = "c",
  DEBIT = "d",
  PRIVATE_LABEL = "p",
}

export type CardBase = {
  user_id: string;
  billing_address_id: string;
  card_linked?: boolean;
  is_default: boolean;
  cvv_verified: boolean;
  created_at: string;
  updated_at: string;
  owner: string;
  last_four: string;
  card_art_url?: string;
  description?: string;
  card_nickname?: string;
  expiration_month: number;
  expiration_year: number;
  network_type: string;
  duplicate_of_card_id?: string;
  card_linking_partner_name?: string;
  card_status?: CardStatus;
  card_status_message?: string;
  sort_order?: number;
  product_type: CardProductType;
};

export enum CardLinkageType {
  UserCard = "UserCard",
  LinkedCard = "LinkedCard",
  UnlinkedCard = "UnlinkedCard",
}

export enum CardLinkageStatus {
  NeedsConfirm = "needsConfirm",
  NeedsComplete = "needsComplete",
  Success = "success",
}

export type UnLinkedCard = {
  card_linkage_type: CardLinkageType.UnlinkedCard;
  card_metadata_id: string;
  issuer_id: string;
} & CardBase;

export type LinkedCard = {
  card_linkage_type: CardLinkageType.LinkedCard;
  card_linkage_status: CardLinkageStatus;
  card_metadata_id: string;
  card_id: string;
  issuer_id: string;
} & CardBase;

export type ManualCard = {
  card_linkage_type: CardLinkageType.UserCard;
  card_id: string;
} & CardBase;

// Very similar to CardDetails with some differences
export type ConsolidatedPaymentMethod = UnLinkedCard | LinkedCard | ManualCard;

export type WalletCard = LinkedCard | ManualCard;
